<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo.png" height="80" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Hosgeldiniz !</h4>
                        <p class="text-muted">Giris Bilgilerinizi Yaziniz</p>
                      </div>

                      <div class="p-2 mt-5">
                        <div class="form-group auth-form-group-custom mb-4">
                          <i class="ri-user-2-line auti-custom-input-icon"></i>
                          <label for="username">Kullanici Adi</label>
                          <input type="text" class="form-control" id="username" v-model="user.kullaniciadi"
                            placeholder="E-mail Adresiniz" v-on:keyup.enter="GirisYap" />
                        </div>

                        <div class="form-group auth-form-group-custom mb-4">
                          <i class="ri-lock-2-line auti-custom-input-icon"></i>
                          <label for="userpassword">Sifreniz</label>
                          <input type="password" v-model="user.password" class="form-control" id="userpassword"
                            placeholder="Sifre" v-on:keyup.enter="GirisYap" />
                        </div>



                        <div class="mt-4 text-center">
                          <button class="btn btn-primary w-md waves-effect waves-light" @click="GirisYap"
                            v-on:keyup.enter="GirisYap">Giris</button>
                        </div>

                        <div class="mt-4 text-center">
                          <router-link tag="a" to="/sifremi-unuttum" class="text-muted">
                            <i class="mdi mdi-lock mr-1"></i> Sifremi Unuttum?
                          </router-link>
                        </div>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © 2021 Subgate Hotspot
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg"
              :style="{'background-image': 'url(' + require('../../../assets/images/background.jpg') + ')'}">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="lisansmodal" hide-footer title="Lisans Kayit">
      <div class="d-block text-center">
        <el-input placeholder="Kullanıcı Adınız" v-model="user.kullaniciadi">
          <template slot="prepend">Kullanıcı Adı</template>
        </el-input>
        <br>
        <br>
        <el-input placeholder="E-mail" type="email" v-model="email">
          <template slot="prepend">E-mail Adresiniz</template>
        </el-input>
        <br>
        <br>
        <el-input placeholder="Lisans Anahtariniz" v-model="lisanskey">
          <template slot="prepend">Lisans Key</template>
        </el-input>
      </div>
      <b-button class="mt-2" variant="info" block @click="lisansKayit">Kaydet</b-button>
    </b-modal>
  </div>
</template>
<script>
  import {
    axiosInstance
  } from "../../../helpers/axios";



  export default {
    name: "Giris",
    data() {
      return {

        user: {
          kullaniciadi: "",
          password: "",
          image: ""
        },
        externalip: "",
        lisanskey: "",
        email: ""

      }
    },
    methods: {

      LicenseUpdate() {
        this.$refs['lisansmodal'].show()
      },
      async GirisYap() {
        try {
          var vm = this;
          if (vm.user.kullaniciadi != '' && vm.user.password != '') {

            await vm.$store.dispatch('auth/login', {
              "kullaniciadi": vm.user.kullaniciadi,
              "password": vm.user.password
            }).then((response) => {
              if (response.error == true) {
                this.$bvToast.toast(response.data.message, {
                  title: `Hata !`,
                  variant: "danger",
                  solid: true
                });

              } else {
                this.$bvToast.toast("Giriş Yapildi Yönlendiriliyorsunuz...", {
                        title: `Bildirim !`,
                        variant:"info",
                        solid: true
                });
                setTimeout(function () {
                  vm.$router.push('/dashboard');
                }, 3000)
              }
            }).catch((error) => {

              this.$bvToast.toast(error, {
                title: `Hata !`,
                variant: "danger",
                solid: true
              });

            });

          } else {

            this.$bvToast.toast("Lutfen Tum alanlari doldurunuz", {
              title: `Hata !`,
              variant: "warning",
              solid: true
            });

          }

        } catch (error) {

          this.$bvToast.toast("Hata Olustu.Lutfen daha sonra tekrar deneyiniz .." + error, {
            title: `Hata !`,
            variant: "danger",
            solid: true
          });

        }
      },
      lisansKayit() {
        var vm = this;
        try {

          if (vm.lisanskey != "") {

            axiosInstance.post("kullanici/lisans/giris", {
              username: vm.user.kullaniciadi,
              serialkey: vm.lisanskey,
              email: vm.email,
            }).then((response) => {

              if (response.data.error == true) {
                this.$bvToast.toast("Hata Olustu.Lutfen daha sonra tekrar deneyiniz ..", {
                  title: `Uyari !`,
                  variant: "warning",
                  solid: true
                });

              } else {
                this.$bvToast.toast("Lisans bilgileriniz onaylandi..", {
                  title: `Uyari !`,
                  variant: "info",
                  solid: true
                });
                setTimeout(function () {
                  window.location.reload();
                }, 3000);
              }

            }).catch((error) => {
              this.$bvToast.toast("Hata Olustu.Lutfen daha sonra tekrar deneyiniz .." + error, {
                title: `Hata !`,
                variant: "danger",
                solid: true
              });
            })

          } else {
            this.$bvToast.toast("Lütfen tüm alanlari eksiksiz doldurunuz", {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          }

        } catch (error) {
          this.$bvToast.toast("Hata Olustu.Lutfen daha sonra tekrar deneyiniz .." + error, {
            title: `Hata !`,
            variant: "danger",
            solid: true
          });
        }
      }

    }
  }
</script>